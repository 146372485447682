<template>
  <div>
    <div class="subheader pb-5 subheader-transparent" id="kt_subheader">
      <div class="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap py-0">
        <div class="d-flex align-items-center flex-wrap mr-1">
          <div class="d-flex align-items-baseline flex-wrap mr-5">
            <h5 class="text-dark font-weight-bolder my-1 mr-5">{{ $t('tariff.tariff_checkout') }}</h5>
            <ul class="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bolder p-0 my-2 font-size-sm">
              <li class="breadcrumb-item">
                <a href="" class="text-muted">{{ $t('tariff.tariffs') }}</a>
              </li>
              <li class="breadcrumb-item">
                <span class="text-muted">{{ $t('tariff.tariff_checkout') }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex flex-column-fluid" v-if="info.id">
      <div class="container py-0">
        <div class="card card-custom">
          <div class="card-body p-0">
            <div class="row">
              <div class="d-none d-lg-block col-lg-4 py-0 border-right">
                <div class="d-flex flex-column py-10 px-10">
                  <div class="d-flex flex-row">
                    <div class="d-flex align-items-center mb-10">
                      <div class="symbol symbol-40 mr-5" :class="step == 0 ? 'symbol-primary' : 'symbol-light-primary'">
                        <span class="symbol-label font-weight-bolder">
                          1
                        </span>
                      </div>
                      <div class="d-flex flex-column">
                        <span class="font-weight-bolder">{{ $t('tariff.about_tariff') }}</span>
                        <span class="text-muted">{{ $t('tariff.check_terms') }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex flex-row">
                    <div class="d-flex align-items-center mb-10">
                      <div class="symbol symbol-40 mr-5" :class="step == 1 ? 'symbol-primary' : 'symbol-light-primary'">
                        <span class="symbol-label font-weight-bolder">
                          2
                        </span>
                      </div>
                      <div class="d-flex flex-column">
                        <span class="font-weight-bolder">{{ $t('tariff.tariff_adjustments') }}</span>
                        <span class="text-muted">{{ $t('tariff.specify_parameters') }}</span>
                      </div>
                    </div>
                  </div>
                  <div v-if="is_phone_check_required" class="d-flex flex-row">
                    <div class="d-flex align-items-center mb-10">
                      <div class="symbol symbol-40 mr-5" :class="step == 2 ? 'symbol-primary' : 'symbol-light-primary'">
                        <span class="symbol-label font-weight-bolder">
                          3
                        </span>
                      </div>
                      <div class="d-flex flex-column">
                        <span class="font-weight-bolder">{{ $t('tariff.phone_confirmation') }}</span>
                        <span class="text-muted">{{ $t('tariff.phone_confirmation2') }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex flex-row">
                    <div class="d-flex align-items-center mb">
                      <div class="symbol symbol-40 mr-5" :class="step == 3 ? 'symbol-primary' : 'symbol-light-primary'">
                        <span class="symbol-label font-weight-bolder">
                          4
                        </span>
                      </div>
                      <div class="d-flex flex-column">
                        <span class="font-weight-bolder">{{ $t('tariff.checkout') }}</span>
                        <span class="text-muted">{{ $t('tariff.checkout_selected_tariff') }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="step == 0" class="col-lg-8 py-0">
                <div class="row p-10">
                  <div class="col-md-6">
                    <span class="text-muted font-weight-bolder">{{ $t('tariff.you_selected') }}</span>
                    <h2 class="font-weight-bolder">{{info.nameFormated}}</h2>
                    <div class="tariff-description pt-4" v-html="info.descr"></div>
                  </div>
                  <div class="col-md-6 mt-5 mt-md-0">
                    <div class="bg-light p-7 rounded">
                      <div class="d-flex justify-content-between mb-5">
                        <div class="text-muted font-weight-bolder text-left">{{ $t('tariff.quantity') }}</div>
                        <div class="text-dark font-weight-bolder text-right d-flex align-items-center">
                          <button v-if="tariff.quantity > 1" class="btn btn-icon btn-xs btn-light-success" @click="minusQuantity">
                            <font-awesome-icon icon="minus" class="font-size-xs" />
                          </button>
                          <div class="text-dark font-weight-bolder mx-3">{{tariff.quantity}}</div>
                          <button class="btn btn-icon btn-xs btn-light-success" @click="plusQuantity">
                            <font-awesome-icon icon="plus" class="font-size-xs" />
                          </button>
                        </div>
                      </div>
                      <div class="d-flex justify-content-between mb-5">
                        <div class="text-muted font-weight-bolder text-left">{{ $t('tariff.duration') }}</div>
                        <div class="text-dark font-weight-bolder text-right">{{tariff.quantity_total}} <span
                            v-if="info.rate.dimension">{{info.rate.dimension.nameLang}}</span></div>
                      </div>
                      <div class="d-flex justify-content-between mb-5">
                        <div class="text-muted font-weight-bolder text-left">{{ $t('tariff.cost') }}</div>
                        <div class="text-dark font-weight-bolder text-right">{{info.rate.total_cost}} <span
                            v-if="tariff.currency">{{tariff.currency.symbol}}</span></div>
                      </div>
                      <div v-if="tariff.discount" class="d-flex justify-content-between mb-5">
                        <div class="text-muted font-weight-bolder text-left">{{ $t('tariff.discount') }}</div>
                        <div class="text-dark font-weight-bolder text-right">{{tariff.discount.value}} <span
                            v-if="tariff.currency">{{tariff.currency.symbol}}</span></div>
                      </div>
                      <hr />
                      <div class="mt-5">
                        <span class="text-muted font-weight-bolder">{{ $t('tariff.total') }}</span>
                        <div class="text-dark font-size-h3 font-weight-900">{{tariff.total_cost}} <span
                            v-if="tariff.currency">{{tariff.currency.symbol}}</span></div>
                        <span class="font-size-xs font-weight-bolder text-muted">{{ $t('tariff.vat') }}</span>
                      </div>
                    </div>
                    <div class="pt-5">
<!--                      <div class="checkbox-list">-->
<!--                        <label class="checkbox">-->
<!--                          <input type="checkbox" name="" v-model="tariff.accept_privacy"/>-->
<!--                          <span></span>-->
<!--                          <small>Я согласен(-на) с Политикой оказания и отказа от услуг</small>-->
<!--                        </label>-->
<!--                        <label class="checkbox">-->
<!--                          <input type="checkbox" name="" v-model="tariff.accept_service"/>-->
<!--                          <span></span>-->
<!--                          <small>Я согласен(-на) с Политикой конфиденциальности</small>-->
<!--                        </label>-->
<!--                      </div>-->
                    </div>
                  </div>
                </div>
                <div class="row pt-5 pb-10 px-10">
                  <div class="col-12">
                    <div class="d-flex align-items-center justify-content-between">
                      <router-link
                          to="/services"
                          v-slot="{ href, route, navigate, isActive, isExactActive }"
                      >
                        <a :href="href" @click="navigate" class="btn btn-secondary">{{ $t('tariff.cancellation') }}</a>
                      </router-link>
                      <template v-if="tariff.can_be_sold">
                        <button v-if="tariff.accept_privacy && tariff.accept_service" class="btn btn-primary"
                                @click="step=step+1">{{ $t('tariff.continue') }}</button>
                      </template>
                      <h3 v-else class="text-danger">{{ $t('tariff.not_be_sold') }}</h3>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="step == 1" class="col-lg-8 py-0">
                <div class="row p-10">
                  <div class="col-12">
                    <span class="text-muted font-weight-bolder">{{ $t('tariff.you_selected') }}</span>
                    <h2 class="font-weight-bolder">{{info.nameFormated}}</h2>
                    <p class="font-weight-bold mt-4">{{ $t('tariff.required_parameters') }}</p>
                    <div class="row mt-4">
                      <div class="col-md-6">
                        <div class="form-group mb-3 mb-md-0">
                          <label class="col-form-label text-right">{{ $t('tariff.start_date') }} <span class="text-danger">*</span></label><br>
                          <date-picker v-model="tariff.start_at" @change="calculateTotal"
                                       valueType="format" format="DD.MM.YYYY"
                                       :disabled-date="disabledBeforeTodayAndAfterAWeek" :lang="$t('data-piker')"></date-picker>
<!--                          <div class="input-group date">-->
<!--                            <input type="text" class="form-control" readonly  placeholder="Выбрать дату..." required />-->
<!--                            <div class="input-group-append">-->
<!--                              <span class="input-group-text">-->
<!--                                <font-awesome-icon icon="calendar-alt" class="text-muted" />-->
<!--                              </span>-->
<!--                            </div>-->
<!--                          </div>-->
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group mb-3 mb-md-0">
                          <label class="col-form-label text-right">{{ $t('tariff.expiration_date') }} <span class="text-danger">*</span></label><br>
                          <input type="text" class="form-control" disabled="disabled" v-model="tariff.end_at" required />
                          <span class="form-text text-muted">{{ $t('tariff.calculated_automatically') }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <div class="form-group">
                          <label for="comment">{{ $t('tariff.comment') }}</label>
                          <textarea class="form-control" id="comment" rows="3" v-model="tariff.comment"
                                    :placeholder="$t('tariff.additional_information')"></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row pt-5 pb-10 px-10">
                  <div class="col-12">
                    <div class="d-flex align-items-center justify-content-between">
                      <button class="btn btn-secondary" @click="step=step-1">{{ $t('tariff.back') }}</button>
                      <button v-if="is_phone_check_required" class="btn btn-primary" @click="step=step+1" :disabled="!tariff.start_at">{{ $t('tariff.continue') }}</button>
                      <button v-else class="btn btn-primary" @click="pay()" :disabled="!tariff.start_at">{{ $t('tariff.pay') }}</button>
                    </div>
                  </div>
                </div>
              </div>
<!--              <div v-if="step == 2" class="col-lg-8 py-0">-->
<!--                <div class="row p-10 h-100">-->
<!--                  <div class="col-md-3"></div>-->
<!--                  <div class="col-md-6 text-center d-flex flex-column justify-content-center">-->
<!--                    <div>-->
<!--                      <span class="text-muted font-weight-bolder">Вы оформляете:</span>-->
<!--                      <h3 class="font-weight-bolder mt-5">{{info.nameFormated}}</h3>-->
<!--                      <p>Теперь Вы можете оплатить тариф.</p>-->
<!--                      <div class="row pt-5 pb-10 px-10">-->
<!--                        <div class="col-12">-->
<!--                          <div class="d-flex align-items-center justify-content-between">-->
<!--                            <button class="btn btn-secondary" @click="step=step-1">Отмена</button>-->
<!--                            <button class="btn btn-primary" @click="pay()">Оплата</button>-->
<!--                          </div>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="col-md-3"></div>-->
<!--                </div>-->
<!--              </div>-->
              <div v-if="step == 2" class="col-lg-8 py-0">
                <div class="row p-10">
                  <div class="col-12">
                    <span class="text-muted font-weight-bolder">{{ $t('tariff.you_selected') }}</span>
                    <h2 class="font-weight-bolder">{{info.nameFormated}}</h2>
                    <p v-if="!phoneByConfirmed" class="font-weight-bold">{{ $t('tariff.confirm_sms_number_desc') }}</p>
                    <p v-if="!phoneByConfirmed" class="font-weight-bold">{{ $t('tariff.confirm_sms_number') }}</p>
                    <p v-if="phoneByConfirmed" class="font-weight-bold">{{ $t('tariff.correctness') }}</p>
                    <p v-if="error" class="alert alert-warning">{{ $t('tariff.' + error) }}</p>
                    <p v-if="error_message" class="alert alert-warning">{{ error_message }}</p>
                    <div class="row mt-4">
                      <div class="col-md-6">
                        <div class="form-group mb-3 mb-md-0">
                          <label class="col-form-label text-right">{{ $t('tariff.telephone_number') }} <span class="text-danger">*</span></label>
                          <div class="input-group">
                            <vue-tel-input
                                class="form-control"
                                v-model="tariff.phone"
                                mode="international"></vue-tel-input>
                            <div class="input-group-append">
                              <button class="btn btn-light-success" type="button" @click="check_phone">{{ $t('tariff.confirm') }}</button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6"></div>
                    </div>
                    <div v-if="phone_send_code" class="row mt-4">
                      <div class="col-md-6">
                        <div class="form-group mb-3 mb-md-0">
                          <label class="col-form-label text-right">{{ $t('tariff.code') }}: <span class="text-danger">*</span></label>
                          <input type="text" v-model="tariff.code" v-mask="'####'" class="form-control"
                                 :placeholder="$t('tariff.code_sms')" :disabled="phone_check_code">
                        </div>
                      </div>
                      <div class="col-md-6"></div>
                    </div>
                  </div>
                </div>
                <div class="row pt-5 pb-10 px-10">
                  <div class="col-12">
                    <div class="d-flex align-items-center justify-content-between">
                      <button class="btn btn-secondary" @click="step=step-1">{{ $t('tariff.back') }}</button>
                      <button :disabled="!phone_confirmed" class="btn btn-primary" @click="pay()">{{ $t('tariff.pay') }}</button>
                    </div>
                  </div>
                </div>
              </div>
<!--              <div v-if="step == 3" class="col-lg-8 py-0">-->
<!--                <div class="row p-10 h-100">-->
<!--                  <div class="col-md-3"></div>-->
<!--                  <div class="col-md-6 text-center d-flex flex-column justify-content-center">-->
<!--                    <div>-->
<!--                      <div class="symbol symbol-45 symbol-light-success mr-4 flex-shrink-0">-->
<!--                        <div class="symbol-label">-->
<!--                          <font-awesome-icon icon="check" class="text-success icon-md"/>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                      <h3 class="font-weight-bolder mt-5">Тариф успешно оплачен!</h3>-->
<!--                      <p>Теперь Вы сможете пользоваться услугами согласно условиям тарифа.</p>-->
<!--                      <a href="#" class="btn btn-block btn-light-primary" @click="step=step+1">На главную</a>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="col-md-3"></div>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div v-if="step == 4" class="col-lg-8 py-0">-->
<!--                <div class="row p-10 h-100">-->
<!--                  <div class="col-md-3"></div>-->
<!--                  <div class="col-md-6 text-center d-flex flex-column justify-content-center">-->
<!--                    <div>-->
<!--                      <div class="symbol symbol-45 symbol-light-danger mr-4 flex-shrink-0">-->
<!--                        <div class="symbol-label">-->
<!--                          <font-awesome-icon icon="ban" class="text-danger icon-md"/>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                      <h3 class="font-weight-bolder mt-5">Что-то пошло не так при оплате тарифа</h3>-->
<!--                      <p>Попробуйте оплатить тариф еще раз или сделайте это позже. Ссылка на оплату отправлена Вам на e-mail.</p>-->
<!--                      <a href="#" class="btn btn-block btn-primary mt-5">Оплатить</a>-->
<!--                      <a href="#" class="btn btn-block btn-light-primary mt-3">К тарифам</a>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="col-md-3"></div>-->
<!--                </div>-->
<!--              </div>-->
              <b-modal v-model="dialogOnlinePaymentDisabledModal" size="sm"
                       @ok="dialogOnlinePaymentDisabledModal = false"
                       centered
                       hide-header
                       hide-footer
                       :ok-only="true">
                <div v-html="$t('tariff.online_payment_disabled_modal_body')"></div>
                <b-button class="mt-10" variant="primary"
                          style="margin: 0 auto; display: block;"
                          @click="dialogOnlinePaymentDisabledModal = false">Ok</b-button>
              </b-modal>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>



<script>
// https://innologica.github.io/vue2-daterange-picker/#props
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

import {mapGetters, mapState, mapActions} from 'vuex'
import moment from 'moment'
import api from "@/plugins/api";

export default {
  components: { DatePicker },
  computed: {
    ...mapState({
      // packages: state => state.dashboard.packages,
    }),
    ...mapGetters({
      identity: 'user/identity',
      phoneByConfirmed: 'user/phoneByConfirmed',
      info: 'tariffServices/info',
      locations: 'tariffServices/locations',
      locationsFilterList: 'tariffServices/locationsFilterList',
    }),
  },
  data: () => {
    return {
      step: 0,
      phone_confirmed: false,
      phone_send_code: false,
      phone_check_code: false,
      is_online_payment_enabled: true,
      dialogOnlinePaymentDisabledModal: false,
      certificate_cnt: 0,
      companies_invoice: [],
      is_phone_check_required: true,
      error: undefined,
      error_message: undefined,
      tariff: {
        can_be_sold: true,
        id: null,
        accept_privacy: true,
        accept_service: true,
        user_id: null,
        location_id: null,
        quantity: 1,
        currency: {},
        discount: {},
        quantity_total: 1,
        total_cost: 0,
        start_at: null,
        end_at: null,
        comment: "",
        phone: "",
        code: ""
      }
    }
  },
  mounted() {
    this.fetch()
    this.tariff.phone = this.phoneByConfirmed
  },
  methods: {
    disabledBeforeTodayAndAfterAWeek(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      return date < today;
      // return date < today || date > new Date(today.getTime() + 7 * 24 * 3600 * 1000);
    },
    checkUserPaymentMethods() {
      if (this.identity.id) {
        let self = this

        api.get('/v2/companies', {}).then(responce => {
          self.companies_invoice = responce.data ? responce.data : {}
        })

        let data = {
          expand: "rate,order",
          not_spent: false,
          sort: "-id", // desc
          state: "current",
          'per-page': 100, // paging not implemented
        };

        api.get('/v2/packages', data)
            .then(responce => {
              let packages = responce.data
              let cnt_used = 0
              let cnt = 0
              if (packages.length > 0) {
                for (let i in packages) {
                  if (packages[i].rate.length > 0) {
                    for (let r in packages[i].rate) {
                      if (packages[i].rate[r].rate.type == 'certificate') {
                        cnt_used = cnt_used + packages[i].rate[r].cnt_used || 0
                        cnt = cnt + packages[i].rate[r].cnt || 0
                      }
                    }
                  }
                }
                self.certificate_cnt = cnt - cnt_used
              }
            }).catch(reason => {
          console.error('reason', reason)
        })
      }
    },
    pay() {
      let self = this
      self.error = undefined
      self.error_message = undefined
      this.checkUserPaymentMethods();
      
      api.post('/v2/orders', {
        user_id: this.tariff.user_id,
        location_id: this.tariff.location_id,
        ts_start: moment(this.tariff.start_at, "DD.MM.YYYY", true).format('X'),
        phone: this.tariff.phone,
        utm_source: "widget",
        comment: this.tariff.comment,
        products: [{
          "type": "tariff",
          "id": this.tariff.id,
          "quantity": this.tariff.quantity,
          "apply_consistently": true
        }]
      }).then(responce => {
        if (!self.is_online_payment_enabled && !self.certificate_cnt && !self.companies_invoice.length) {
          self.dialogOnlinePaymentDisabledModal = true;
        } else {
          window.location.href = window.__env.checkout_url.replace(/{guid}/i, responce.data.guid)
          // `https://my.spacepass.me/uu/#/order?id=${responce.data.guid}`
        }
      }).catch(reason => {
        self.error_message = reason.response.data.error.message
      })
    },
    check_phone() {
      let self = this
      self.error = undefined
      self.error_message = undefined
      api.put('/v2/users/phone-by-number', {
        phone: this.tariff.phone
      }).then(responce => {
        if (responce.data.is_confirmed) {
          self.phone_confirmed = true
          self.phone_send_code = false
        }
        else {
          self.send_verification_code()
        }
      }).catch(e => {
        self.send_verification_code()
      })
    },
    send_verification_code() {
      let self = this
      self.error = undefined
      self.error_message = undefined
      api.post('/v2/users/send-phone-code', {
        phone: this.tariff.phone
      }).then(responce => {
        self.phone_send_code = true
        self.phone_confirmed = false
        self.error = undefined
      }).catch(e => {
        self.error = 'error_send_verification_code'
      })
    },
    confirm_phone() {
      let self = this
      self.error = undefined
      self.error_message = undefined
      api.get('/v2/users/confirm-phone', {
        code: this.tariff.code
      }).then(responce => {
        self.phone_confirmed = true
      }).catch(e => {
        self.error = 'error_check_verification_code'
        self.phone_check_code = false
      })
    },
    fetch() {
      let self = this
      self.tariff.start_at = moment().format("DD.MM.YYYY");
      self.$store.dispatch('tariffServices/GetInfo', this.$route.params.id).then(function() {
        self.tariff.id = self.info.id
        self.tariff.user_id = self.identity.id
        self.tariff.location_id = self.info.location_ids ? self.info.location_ids[0] : null
        self.$store.dispatch('tariffServices/GetLocationSimpleInfo', self.tariff.location_id)
            .then((res) => {
              self.is_online_payment_enabled = res.is_online_payment_enabled;
              self.is_phone_check_required = res.is_phone_check_required;
            })
        self.calculateTotal()
      });
    },
    plusQuantity() {
      this.tariff.quantity = this.tariff.quantity + 1
      this.calculateTotal()
    },
    minusQuantity() {
      this.tariff.quantity = this.tariff.quantity - 1
      this.calculateTotal()
    },
    calculateTotal() {
      let self = this
      api.get('/v2/price/tariff/' + self.tariff.id, {
        location_id: this.tariff.location_id,
        quantity: this.tariff.quantity,
        from: moment(this.tariff.start_at, "DD.MM.YYYY", true).format('X')
      }).then(responce => {
        this.tariff.can_be_sold = responce.data.canBeSold
        this.tariff.discount = responce.data.discount
        this.tariff.currency = responce.data.currency
        this.tariff.total_cost = responce.data.totalCost
        self.tariff.end_at = moment.unix(responce.data.to).format('DD.MM.YYYY')
        this.tariff.quantity_total = this.info.rate.quantity * this.tariff.quantity
      })
    }
  },
  watch: {
    filter: {
      handler() {
      },
      deep: true,
    },
    'tariff.code': {
      handler() {
        if (this.tariff.code.length == 4) {
          let self = this
          self.phone_check_code = true
          self.confirm_phone()
        }
      },
      deep: false,
    },
  },
}
</script>
